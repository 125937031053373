<template>
  <v-card>
    <v-tabs
      v-model="tab"
      background-color="deep-purple accent-4"
      dark
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        Общая информация
      </v-tab>

      <v-tab href="#tab-2">
        Таблица видов
      </v-tab>

      <v-tab href="#tab-3">
        Заключение
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item id="tab-1">
        <v-card flat>
          <v-card-text>Общая информация</v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item id="tab-2">
        <v-card flat>
          <v-card-text>Таблица видов</v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item id="tab-3">
        <v-card flat>
          <v-card-text>Заключение</v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
    data: ()=>({
        tab:null
    })
}
</script>

<style>

</style>