<template>
    <v-list dense expand>
      <v-list-group
        :value="true"
        prepend-icon="mdi-file-sign"
        no-action
        mandatory
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>ЭКСПЕРТИЗЫ</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(item, i) in expertise"
          :key="i+item.text"
          link
          :to=item.href

        >
          <v-list-item-content>
            <v-list-item-title>{{item.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group
        :value="true"
        no-action
        prepend-icon="mdi-list-box-outline"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>СПРАВОЧНИКИ</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(item, i) in books"
          :key="i"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{item.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
</template>

<script>
export default {
  name: "Sidebar",

  data: () => ({
    expertise: [
      {
        text: "Почерковедческая",
        href: "/expertises",
      },
      {
        text: "Автороведческая",
        href: "/expertises1",
      },
      {
        text: "Техническая",
        href: "/expertises2",
      },
      {
        text: "Взрывотехническая",
        href: "/expertises3",
      },
      {
        text: "Почвоведческая",
        href: "/expertises4",
      },
      {
        text: "Биологическая",
        href: "/expertises5",
      },
      {
        text: "Автотехническая",
        href: "/expertises6",
      },
      {
        text: "Пожарно-техническая",
        href: "/expertises7",
      },
      {
        text: "Взрывотехнологическая",
        href: "/expertises8",
      },
      {
        text: "Бухгалтерская",
        href: "/expertises9",
      },
      {
        text: "Товароведческая",
        href: "/expertises10",
      },
    ],
    books: [
      {
        text: "Суды",
        href: "/expertises11",
      },
    ],

  }),
};
</script>