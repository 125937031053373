import Vue from 'vue';
import Vuex from 'vuex';

import expertisesModule from "@/store/expertisesModule";

Vue.use(Vuex);

export default new Vuex.Store({
    modules:{
        expertisesModule
    }
})