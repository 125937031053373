<template>
  <v-app>
    <v-navigation-drawer
      app
      left
      v-model="drawer"
      fixed
    >
      <v-list-item>
        <v-list-item-content>
          <v-img src="@/assets/logo.png" max-height="100" contain></v-img>
          <v-list-item-title class="text-h6">
            ЭПСЭ "Экспертиза"
          </v-list-item-title>
          <v-list-item-subtitle>
            subtext
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    <Sidebar/>
  </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer">
      </v-app-bar-nav-icon>
      <v-app-bar-title>
        <h3 class="text-h4 white--text">ЭПСЭ "Экспертиза"</h3>
      </v-app-bar-title>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>

  </v-app>
</template>

<script>

import Sidebar from './components/Sidebar.vue';

export default {
  name: 'App',
  components: {Sidebar},
  data: () => ({
    drawer: true
  })
}
</script>

<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.v-main{
  background: #F4F5FA;
}
</style>
