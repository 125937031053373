<template>
  <v-container>
    <ExpertiseCard v-for="(item,i) in this.$store.getters.getAllExpertises" :key="i" :expertise="item" />
    <v-btn
      color="pink"
      dark
      fixed
      bottom
      right
      fab
      @click="dialog = !dialog"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Новая экспертиза</v-card-title>
        <v-card-text>
          <v-form v-model="isValid" lazy-validation ref="newCaseForm">
            <v-text-field
              label="Номер дела"
              required
              v-model="caseNumber"
              :rules="[v => v.length>0 || 'Обязательное поле']"
            >
            </v-text-field>
          </v-form>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="submitNewCase"
          >
            Создать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src
import ExpertiseCard from './ExpertiseCard.vue';
export default {
  name: "ExpertisesList",
  components: { ExpertiseCard },
  data: ()=>({
    dialog:false,
    isValid: true,
    caseNumber:'',
  }),
  methods:{
    submitNewCase(){
      // this.$refs.newCaseForm.validate()
      console.log(this.isValid)
    }
  },
  mounted(){
    this.$store.dispatch('fetchExpertises');
  }
};
</script>