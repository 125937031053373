<template>
  <ExpertisesList />
</template>

<script>
import ExpertisesList from '../components/Expertises/ExpertisesList.vue';
export default {
    components: { ExpertisesList },
}
</script>

<style>

</style>