<template>
    <v-row>
        <v-col>
            <v-card
                elevation="2"
                class="mx-auto"
                max-width="600"
                outlined
            >
                <v-list-item three-line>
                <v-list-item-content>
                    <div class="d-flex align-center justify-lg-space-between">
                        <span class="text-overline mb-4">
                        #{{expertise.id}}
                        </span>
                        <span class="text-sm-body-2 mb-4 text--secondary ml-4">
                            <v-icon small>mdi-calendar-import-outline</v-icon>
                            Дата создания: {{getDate(expertise.dateCreate)}}</span>
                    </div>
                    <v-row>
                        <v-col>
                            <v-list-item-title class="text-h5 mb-1">
                            Дело: {{expertise.courtCaseNumber}}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <v-icon small>mdi-gavel</v-icon> {{ expertise.court || 'Не указано' }}</v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon small>mdi-map</v-icon> {{ expertise.courtAddress || 'Не указано' }}</v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-icon small>mdi-account</v-icon> {{ expertise.judge || 'Не указано' }}</v-list-item-subtitle>
                        </v-col>
                        <v-col class="col-auto">
                            <v-progress-circular
                            :rotate="360"
                            :size="100"
                            :width="15"
                            :value="expertise.progress"
                            color="teal"
                            >
                            {{expertise.progress}}
                            </v-progress-circular>
                        </v-col>
                    </v-row>

                </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-chip
                    class="ma-2"
                    :color="getStateColor"
                    >
                    {{expertise.state}}
                    </v-chip>
                <v-chip
                class="ma-2"
                :color="getPriotityColor"
                text-color="white"
                >
                {{expertise.priority}}
                </v-chip>
                <v-chip
                class="ma-2"
                color="primary"
                outlined
                pill
                >
                Прошло дней: {{ expertise.daysLeft }}
                </v-chip>
                <v-spacer></v-spacer>
                <v-btn
                color="primary"
                elevation="2"
                small
                :to="'expertise/'+expertise.id"
                >
                <v-icon dark left>
                    mdi-pencil
                </v-icon>
                Редактировать</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

</template>

<script>
export default {
    props:{
        expertise:{
            type: Object,
            require: true
        }
    },
    methods:{
        getDate(d){
            let date = d.split(' ');
            let dateArr = date[0].split('.')
            let dateTime = date[1].split(':')
            let dateString = new Date(parseInt(dateArr[2]),parseInt(dateArr[1]),parseInt(dateArr[0]),parseInt(dateTime[0]),parseInt(dateTime[1]));

            return dateString.toLocaleString('ru-RU');
        }
    },
    computed:{
        getStateColor(){
            switch (this.expertise.state) {
                case 'Выполнен':
                    return 'green';
                case 'Отменён':
                    return 'default';
                case 'Создан':
                    return 'blue';
                default:
                    return 'green';
            }
        },
        getPriotityColor(){
            switch (this.expertise.priority) {
                case 'Срочный':
                    return 'red';
                case 'Высокий':
                    return 'orange';
                case 'Обычный':
                    return 'primary';
                case 'Низкий':
                    return 'default';
                default:
                    return 'green';
            }
        }
    }
}
</script>

<style>

</style>