export default {
    state:{
        expertises : []
    },
    mutations: {
        setExpertises(state,payload){
            state.expertises = payload
        }
    },
    getters: {
        getAllExpertises(state){
            return state.expertises
        }
    },
    actions:{
        fetchExpertises(context){
            fetch("https://expertise-develop-bse.amvera.io/api/v1/expertises")
              .then((response) => response.json())
              .then((data) => context.commit("setExpertises", data.listExpertises));
        }
    }
}